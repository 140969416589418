import React from "react"
import Image from "../images/rabbit-lifting.svg"
import Join from "../components/join"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CoachingPage = () => (
  <Layout>
    <Seo title="Coaching" />

    <header>
      <img
        src={Image}
        width={333}
        height={260}
        alt="restful coder logo"
      />
    </header>

    <br />
    <br />

    <section>
      <h2>Training</h2>

      <p>Restful Coder <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC8In_UXkaO_FU7oCDRMR8Eg">provides training</a> to help you reduce stress and to increase your confidence in your work. Through a series of software engineering best practices and software delivery life-cycle techniques, I can help you feel more confident in your day-to-day work.</p>

      <h2>Videos</h2>

      <p>Check out my <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC8In_UXkaO_FU7oCDRMR8Eg">YouTube channel</a> for a dozens of videos on software development, automated testing, and refactoring.</p>

      <h2>Code review</h2>

      <p>Each week I will review a piece of code. The purpose of code review is to provide constructive feedack and suggestions. The purpose of code review is not to make rude comments or to leave unconstructive feedback.</p>

      <h2>Video calls</h2>

      <p>If you are interested in a group or 1-on-1 video call session, please reach out via Twitter.</p>
    </section>

    <Join />
  </Layout>
)

export default CoachingPage
